import React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout'

import {PostContent} from 'components/posts/single'


const SinglePostTemplate = (props) => {
  const data = props.data;
  const {title, slug, classes, ancestors} = data.wordpressPost
  return (
    <Layout
      articleId={"posts-"+slug}
      articleClassName={classes}
      context = {props.pageContext}
      location = {props.location}
      // ancestors={[{name: title, slug: slug} , ...ancestors]}
      >
        <PostContent {...props.data.wordpressPost}/>

    </Layout>
  )
};


export default SinglePostTemplate

export const postsQuery = graphql `
query postById($id: String!){
  wordpressPost(id:{eq:$id}){
    title
    content
    path
    slug
    type
    classes
    date(formatString: "LL")
    wordpress_id
    tags {
      name
    }
    featured_media {
      localFile {
        ...PageBannerImages
      }
    }
    related_posts_section{
        title
        items{
          wordpress_id
          path
          date(formatString: "LL")
          title
          tags {
            name
          }
          featured_media  {
            localFile {
              childImageSharp {
                fluid ( maxWidth:370){
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
