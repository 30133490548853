import React from 'react'
import {ContentCardWithMeta} from 'components/cards/contentCardWithMeta'
import {withGrid} from 'components/grid'

export default (props) => {
  const items = props.items.map(item => {
    let image = false;
    if(item.featured_media && item.featured_media.localFile && item.featured_media.localFile.childImageSharp && item.featured_media.localFile.childImageSharp.fluid){
      image = item.featured_media.localFile.childImageSharp.fluid
    }
    return {
      tags:item.tags && item.tags.length?[item.tags[0]]:[],
      image:image,
      title:item.title,
      date:item.date,
      link:{
        url:item.path,
        title:"read more"
      }
    }
  });
  const NewsGrid = withGrid(
    ContentCardWithMeta,
    items
  )
  return (
    <NewsGrid/>
  )
}
