import React from "react"
import {PostSection} from 'components/sections'
import RelatedPosts from "../relatedPosts"

export const Footer =  (props) => (
  <>
    {
      props.related_posts_section
        && props.related_posts_section.items
        && props.related_posts_section.items.length > 0
        &&
    <PostSection
      title={props.related_posts_section.title}
    >
      <RelatedPosts {...props.related_posts_section}/>


     </PostSection>
    }
  </>
)
