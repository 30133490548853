import React from 'react'
import Styles from './single.module.css'
import {ContentWithShare} from 'components/ContentWithShare'
import {Header} from './parts/header'
import {Footer} from './parts/footer'

export const PostContent = (props) => (
  <>
  <header className="entry-header mb-10">
    <Header
      {...props}
    />
  </header>
    <ContentWithShare
      twitter={true}
      facebook={true}
      linkedin={true}
    >
      
  <div className="entry-content" dangerouslySetInnerHTML={{__html:props.content}}/>
      
    </ContentWithShare>
    <footer className="entry-footer mt-10">
      <Footer {...props}/>
    </footer>
  </>
)
